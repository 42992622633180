import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    plan: null,
    recurrence: null,
    paymentMethod: null,
    paymentOptions: null,
    plans: null,
    plansName: null,
    plansMonthly: null,
    planSelectedRecurrencies: null,
    chosenPlan: null,
    order: null,
    pending: false
  },
  actions,
  getters,
  mutations,
  namespaced: true
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: { "back-button-action": _vm.backBtnAction },
        scopedSlots: _vm._u([
          {
            key: "back",
            fn: function () {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$t("checkout.back_payment_method")) + " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "header",
            fn: function () {
              return [
                [
                  _c(
                    "span",
                    { attrs: { "data-cy": "choose-wizard_control-text" } },
                    [_vm._v(" 2 " + _vm._s(_vm.$t("checkout.of")) + " 2 — ")]
                  ),
                ],
                _c(
                  "strong",
                  { attrs: { "data-cy": "choose-wizard_control_choose-text" } },
                  [_vm._v(" " + _vm._s(_vm.$t("checkout.nf_emit")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "sub-header",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("plan")) + " "),
                _c(
                  "strong",
                  { attrs: { "data-cy": "payment_method-plan-text" } },
                  [
                    _vm._v(
                      _vm._s(_vm.getDefaultPlanName) +
                        " " +
                        _vm._s(
                          _vm.$t("recurrences_num")[
                            _vm.getPlan?.recurrencePeriod
                          ]
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(" — "),
                _c("span", {
                  attrs: { "data-cy": "choose-plan_price-text" },
                  domProps: { innerHTML: _vm._s(_vm.planPrice) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("box-snippet", { attrs: { routeName: _vm.routeEdit } }, [
        _c("div", [
          _c("div", { staticClass: "mb-2" }, [
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v(" " + _vm._s(_vm.$t("name")) + ": "),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.getProfile?.person?.owner))]),
          ]),
          _c("div", {}, [
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v(" " + _vm._s(_vm.$t("document")) + ": "),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.getProfile?.person.cpf_cnpj))]),
          ]),
        ]),
      ]),
      _c("box-snippet", { attrs: { routeName: _vm.routeEdit } }, [
        _c("div", [
          _c("div", { staticClass: "mb-2" }, [
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v(" " + _vm._s(_vm.$t("address")) + ": "),
            ]),
          ]),
          _c("div", {}, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.getProfile.storeAddress.street) +
                  ", " +
                  _vm._s(_vm.getProfile.storeAddress.number) +
                  " "
              ),
              _vm.getProfile.storeAddress.complement
                ? _c("span", [
                    _vm._v(
                      " - " + _vm._s(_vm.getProfile.storeAddress.complement)
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", {}, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.getProfile.storeAddress.neighbourhood) +
                  " - " +
                  _vm._s(_vm.getProfile.storeAddress.city) +
                  "/" +
                  _vm._s(_vm.getProfile.storeAddress.state) +
                  " - " +
                  _vm._s(_vm.getProfile.storeAddress.zipcode)
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "advance-btn" },
        _vm._l(this.paymentOptions, function (item) {
          return _c(
            "div",
            {
              key: item,
              staticClass: "payment-btns",
              attrs: { "data-cy": `payment-method-${item}` },
            },
            [
              _c("submit-button-default", {
                staticClass: "payment-btns",
                staticStyle: { height: "73px", "max-width": "100%" },
                attrs: {
                  text: _vm.getButtomText(item),
                  "data-cy": "`checkout-submit1-btn-${item}`",
                  loading: _vm.loading,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.getPaymentFunction(item)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="app">
    <loading v-if="isApplicationLoading" />
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, LOGIN_MODULE } from "./store/constants/login";
import { STORE, STORE_MODULE } from "./store/constants/store";
import Loading from "@/components/Loading.vue";
import { EXEC_AUTH, EXEC_WIDGET, OPENS_MODULE } from "./store/constants/opens";
export default {
  components: {
    Loading,
  },

  mounted() {
    // TODO: Ajustar aqui para aceitar clientes internacionais, não deve buscar do navegador, mas do idioma definido de alguma forma pelo próprio cliente, sendo o navegador a última opção se nada for definido previamente
    this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE; // window.navigator.language;
  },

  computed: {
    ...mapGetters({
      isApplicationLoading: "isApplicationLoading",
      getProfile: `${STORE_MODULE}/${STORE}`,
    }),
  },
  watch: {
    getProfile: {
      handler() {
        const email = this.getProfile?.store?.account;
        const name = this.getProfile?.person?.owner;

        if (!name || !email) return;

        this.execAuth({ email, name });
      },
      deep: true,
    },
    contactID: {
      handler() {
        const language = this.getProfile?.lang;
        this.execWidget({ language }).then((response) => {
          const { div, script } = response;
          const app = document.getElementById("app");
          app.append(div);
          app.append(script);
        });
      },
    },
  },
  methods: {
    ...mapState({
      contactID: (state) => state.opens.contactID,
    }),
    ...mapActions({
      logout: `${LOGIN_MODULE / AUTH_LOGOUT}`,
      execAuth: `${OPENS_MODULE}/${EXEC_AUTH}`,
      execWidget: `${OPENS_MODULE}/${EXEC_WIDGET}`,
    }),
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

import { render, staticRenderFns } from "./IconClock.vue?vue&type=template&id=55b74780&"
import script from "./IconClock.vue?vue&type=script&lang=js&"
export * from "./IconClock.vue?vue&type=script&lang=js&"
import style0 from "./IconClock.vue?vue&type=style&index=0&id=55b74780&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1673984875/src/minhaloja-v2-international/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55b74780')) {
      api.createRecord('55b74780', component.options)
    } else {
      api.reload('55b74780', component.options)
    }
    module.hot.accept("./IconClock.vue?vue&type=template&id=55b74780&", function () {
      api.rerender('55b74780', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Icons/IconClock.vue"
export default component.exports
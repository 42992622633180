<template>
  <div class="box-message-card">
    <div class="flex flex-wrap p-0 justify-between w-full">
      <div class="panel-left" data-cy="cancel-banner-text">
        <box-message-welcome
          :title="$t('cancel_plan.title')"
          :emoji-codes="[721]"
        />

        <p>{{ $t("cancel_plan.farewellMessage") }}</p>
        <br />
        <p>
          {{ $t("cancel_plan.buttonSolicitCancel") }}
        </p>
        <br />
        <p>
          {{ $t("cancel_plan.informationsToCancel") }}
        </p>
        <div class="mt-8">
          <SubmitButtonDefault
            :text="$t('initChat')"
            :show-icon="false"
            data-cy="cancel-chat-btn"
            @click.native="
              openChat();
              eventGtm('custom.clickCancelButton');
            "
          />
        </div>
      </div>

      <div class="panel-right" data-cy="cancel-panel-text">
        <h1 class="text-lg font-semibold mb-10">
          {{ $t("cancel_plan.benefits") }}
        </h1>
        <p v-for="(info, i) in infoText" :key="i" class="mb-2">
          <IconTimes icon-style="solid" color="red" class="h-5 inline" />
          {{ info.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BoxMessageWelcome from "@/components/Home/BoxMessageWelcome.vue";
import { IconTimes } from "@/components/Icons/index";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import FEATURES from "@/constants/plans-features";
import { mapGetters } from "vuex";
import { STORE_MODULE } from "@/store/constants/store";
export default {
  name: "CancelPlanView",
  components: {
    BoxMessageWelcome,
    IconTimes,
    SubmitButtonDefault,
  },
  computed: {
    ...mapGetters(STORE_MODULE, ["subscription"]),
    ...mapGetters({
      eventGtm: "eventGtm",
    }),
    infoText() {
      const plan = parseInt(this.subscription?.license_type, 10);
      return FEATURES.filter((item) => item.plan === plan);
    },
  },
  methods: {
    openChat() {
      const parent = document.getElementById("yunique-chat-app");
      const chat = parent.children[0]?.children[2];
      if (!chat) {
        return;
      }
      chat.click();
    },
  },
};
</script>
<style lang="scss">
.box-message-text p {
  @apply text-xl font-normal;
}
.panel-left {
  @apply w-2/3 text-left;
}
.panel-right {
  @apply w-1/3 border-l-2 pl-5 text-left;
}

@media (max-width: 640px) {
  .panel-right {
    @apply w-full mt-5 border-t-2 border-l-0 pt-5;
  }
  .panel-left {
    @apply w-full;
  }
  .box-message-text {
    @apply p-0;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: { "back-button-action": false },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                [
                  _c(
                    "span",
                    { attrs: { "data-cy": "choose-wizard_control-text" } },
                    [_vm._v(" 4 " + _vm._s(_vm.$t("checkout.of")) + " 4 — ")]
                  ),
                ],
                _c(
                  "strong",
                  { attrs: { "data-cy": "choose-wizard_control_choose-text" } },
                  [_vm._v(" " + _vm._s(_vm.$t("checkout.bankslip_finish")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "sub-header",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("plan")) + " "),
                _c(
                  "strong",
                  { attrs: { "data-cy": "payment_method-plan-text" } },
                  [
                    _vm._v(
                      _vm._s(_vm.getDefaultPlanName) +
                        " " +
                        _vm._s(
                          _vm.$t("recurrences_num")[
                            _vm.getPlan?.recurrencePeriod
                          ]
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(" — "),
                _c("span", {
                  attrs: { "data-cy": "choose-plan_price-text" },
                  domProps: { innerHTML: _vm._s(_vm.planPrice) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "alert alert-info", attrs: { role: "alert" } }, [
        _c("h4", { staticClass: "alert-heading" }, [
          _c("b", [_vm._v(_vm._s(_vm.$t("checkout.bankslip_alert_heading")))]),
        ]),
        _vm._v(" " + _vm._s(_vm.$t("checkout.bankslip_alert_info")) + " "),
      ]),
      _vm.renderIFrameWithZoom
        ? _c("div", { staticClass: "my-5" }, [
            _c("iframe", {
              staticClass: "iframe",
              attrs: {
                src: _vm.getBankslipUrl,
                title: "description",
                frameborder: "0",
              },
            }),
            _c("p", { staticClass: "text-center mb-3 mt-5 text-lg" }, [
              _vm._v(" " + _vm._s(_vm.$t("checkout.send_bankslip")) + " "),
            ]),
          ])
        : _c("div", [
            _c("iframe", {
              staticClass: "iframe-page",
              attrs: {
                src: _vm.getBankslipUrl,
                title: "description",
                frameborder: "0",
              },
            }),
          ]),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c("submit-button-default", {
            attrs: {
              text: _vm.$t("understand"),
              "data-cy": "checkout_bankslip-submit-btn",
            },
            nativeOn: {
              click: function ($event) {
                _vm.backBtnAction()
                _vm.eventGtm("custom.bankslip-Success")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
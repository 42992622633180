import { render, staticRenderFns } from "./IconLongArrow.vue?vue&type=template&id=563cf2c2&"
import script from "./IconLongArrow.vue?vue&type=script&lang=js&"
export * from "./IconLongArrow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1673984875/src/minhaloja-v2-international/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('563cf2c2')) {
      api.createRecord('563cf2c2', component.options)
    } else {
      api.reload('563cf2c2', component.options)
    }
    module.hot.accept("./IconLongArrow.vue?vue&type=template&id=563cf2c2&", function () {
      api.rerender('563cf2c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Icons/IconLongArrow.vue"
export default component.exports
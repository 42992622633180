<template>
  <div class="container">
    <CheckoutHeader :back-button-action="backBtnAction" class="-mb-4">
      <template v-slot:back>
        {{ $t("checkout.back_payment_method") }}
      </template>
      <template v-slot:header>
        <template>
          <span data-cy="choose-wizard_control-text">
            2 {{ $t("checkout.of") }} 2 &mdash;
          </span>
        </template>
        <strong data-cy="choose-wizard_control_choose-text">
          {{ $t("checkout.nf_emit") }}</strong
        >
      </template>
      <template v-slot:sub-header>
        {{ $t("plan") }}
        <strong data-cy="payment_method-plan-text"
          >{{ getDefaultPlanName }}
          {{ $t("recurrences_num")[getPlan?.recurrencePeriod] }}
        </strong>
        &mdash;
        <span data-cy="choose-plan_price-text" v-html="planPrice" />
      </template>
    </CheckoutHeader>
    <box-snippet :routeName="routeEdit">
      <div>
        <div class="mb-2">
          <span class="font-semibold"> {{ $t("name") }}: </span>
          <span>{{ getProfile?.person?.owner }}</span>
        </div>
        <div class="">
          <span class="font-semibold"> {{ $t("document") }}: </span>
          <span>{{ getProfile?.person.cpf_cnpj }}</span>
        </div>
      </div>
    </box-snippet>

    <box-snippet :routeName="routeEdit">
      <div>
        <div class="mb-2">
          <span class="font-semibold"> {{ $t("address") }}: </span>
        </div>
        <div class="">
          <span
            >{{ getProfile.storeAddress.street }},
            {{ getProfile.storeAddress.number }}
            <span v-if="getProfile.storeAddress.complement">
              - {{ getProfile.storeAddress.complement }}</span
            ></span
          >
        </div>
        <div class="">
          <span
            >{{ getProfile.storeAddress.neighbourhood }} -
            {{ getProfile.storeAddress.city }}/{{
              getProfile.storeAddress.state
            }}
            - {{ getProfile.storeAddress.zipcode }}</span
          >
        </div>
      </div>
    </box-snippet>

    <div class="advance-btn">
      <div
        class="payment-btns"
        v-for="item in this.paymentOptions"
        :key="item"
        :data-cy="`payment-method-${item}`"
      >
        <submit-button-default
          class="payment-btns"
          style="height: 73px; max-width: 100%"
          :text="getButtomText(item)"
          data-cy="`checkout-submit1-btn-${item}`"
          @click.native="getPaymentFunction(item)"
          :loading="loading"
        />
      </div>
      <!-- <div class="payment-btns">
        <submit-button-default
          class="payment-btns"
          style="height: 73px; max-width: 100%"
          :text="$t('checkout.pay_credit_card')"
          data-cy="checkout-submit1-btn"
          @click.native="
            nextStepCreditCard('CreditCard');
            eventGtm('custom.paymentCreditCard');
          "
          :loading="loading"
        />
      </div> -->
      <!--          <div class="payment-btns">
                <submit-button-default
                    class="payment-btns"
                    style="height: 73px; max-width: 100%;"
                    :text="$t('checkout.pay_pagoFacil')"
                    data-cy="checkout-submit1-btn"
                    @click.native="nextStepBankslip('PagoFacil')"
                    :loading="loading"
                />
            </div>
            <div class="payment-btns">
                <submit-button-default
                    class="payment-btns"
                    style="height: 73px; max-width: 100%;"
                    :text="$t('checkout.pay_rapiPago')"
                    data-cy="checkout-submit1-btn"
                    @click.native="nextStepBankslip('RapiPago')"
                    :loading="loading"
                />
            </div>
-->
    </div>
  </div>
</template>

<script>
import {
  CHECKOUT_BANKSLIP_VIEW,
  CHECKOUT_CREDIT_CARD_VIEW,
  CHECKOUT_PROFILE_EDIT_VIEW,
  PAYMENT_METHOD_SELECTOR_VIEW,
  PLAN_SELECTOR_VIEW,
  BANKSLIP_PDF_VIEW,
} from "@/router/constants";
import {
  CHECKOUT_MODULE,
  GET_RECURRENCE,
  GET_PLAN,
  CHANGE_PAYMENT_METHOD,
  GET_CHOSEN_PLAN,
  EXEC_CHECKOUT,
  GET_PAYMENT_OPTIONS,
} from "../store/constants/checkout";
import { STORE } from "../store/constants/store";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import BoxSnippet from "@/components/Checkout/BoxSnippet.vue";
import { STORE_MODULE } from "@/store/constants/store";
import Utils from "@/utils/Utils";

export default {
  name: PAYMENT_METHOD_SELECTOR_VIEW,
  components: {
    CheckoutHeader,
    SubmitButtonDefault,
    BoxSnippet,
  },
  data() {
    return {
      routeEdit: CHECKOUT_PROFILE_EDIT_VIEW,
      loading: false,
      isArtentina: false,
      paymentOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      getRecurrence: `${CHECKOUT_MODULE}/${GET_RECURRENCE}`,
      getPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
      getChosenPlan: `${CHECKOUT_MODULE}/${GET_CHOSEN_PLAN}`,
      getProfile: `${STORE_MODULE}/${STORE}`,
      getPaymentOptions: `${CHECKOUT_MODULE}/${GET_PAYMENT_OPTIONS}`,
      eventGtm: "eventGtm",
      isDesktop: `isDesktop`,
    }),

    isRecurrenceSelected() {
      return this.getRecurrence?.name;
    },
    getDefaultPlanName() {
      return this.$t("plan_default_name")[this.getPlan?.name];
    },
    planPrice() {
      return `${this.getPlan?.currencySymbol} ${this.getPlan?.price}<small>,00
        <em>${
          this.$t("recurrences_freq_num")[this.getPlan?.recurrencePeriod]
        }</em>
        </small>`;
    },
  },
  async created() {
    this.isArtentina = this.getProfile.billingAddress.country === "AR";
    if (!this.getPlan) {
      return this.$router.push({
        name: PLAN_SELECTOR_VIEW,
      });
    }
    if (
      !this.getProfile.storeAddress.street ||
      !this.getProfile?.person?.owner
    ) {
      return this.$router.push({
        name: CHECKOUT_PROFILE_EDIT_VIEW,
      });
    }

    this.paymentOptions = Object.keys(this.getPaymentOptions);
    console.log({ data: this.paymentOptions });
  },
  methods: {
    ...mapActions({
      changePaymentMethod: `${CHECKOUT_MODULE}/${CHANGE_PAYMENT_METHOD}`,
      execCheckout: `${CHECKOUT_MODULE}/${EXEC_CHECKOUT}`,
    }),

    backBtnAction() {
      return this.$router.push({
        name: PLAN_SELECTOR_VIEW,
      });
    },
    nextStepCreditCard(paymentMethod) {
      this.setPaymentMethod(paymentMethod);
      return this.$router.push({
        name: CHECKOUT_CREDIT_CARD_VIEW,
      });
    },
    async nextStepBankslip(paymentMethod) {
      this.loading = true;
      this.setPaymentMethod(paymentMethod);
      try {
        const response = await this.execCheckout({
          chosenPlan: this.getPlan,
          paymentMethod,
        });
        if (response.error) {
          return this.errorHandling(this.handleAcquireError(response.error));
        }
        if (this.isDesktop) {
          return this.$router.push({
            name: BANKSLIP_PDF_VIEW,
          });
        }
        return this.$router.push({
          name: CHECKOUT_BANKSLIP_VIEW,
        });
      } catch (error) {
        this.errorHandling(error);
      } finally {
        this.loading = false;
      }
    },
    handleAcquireError(error) {
      if (!error) {
        return this.$t("checkout.cupon_error.default");
      }

      const errorsCollection = {
        "BP-DOC-01": this.$t("checkout.cupon_error.documento"),
      };

      if (!errorsCollection[error.code]) {
        return this.$t("checkout.cupon_error.default");
      }

      return errorsCollection[error.code];
    },
    errorHandling(error) {
      let message = error.message || error;
      if (!message) {
        message = this.$t("checkout.cupon_error.default");
      }
      this.$fire({
        text: message,
        type: "error",
        timer: 6000,
        customClass: "alert-error-1",
      });
      throw new Error(JSON.stringify(error));
    },
    line1(recurrence) {
      return `<strong>${
        this.$t("recurrences")[recurrence.name]
      }</strong> &mdash; ${this.$t("currency.sign")}
            ${recurrence.amount}  <small class='text-cool-gray-500'>${
        this.$t("recurrences_freq")[recurrence.name]
      }</small>`;
    },
    line2(discount) {
      if (discount === 0) {
        return `<em>${this.$t("checkout.no_discount")}</em>`;
      }
      return `<em>${this.$t("checkout.pre_discount")} ${this.$t(
        "currency.sign"
      )} ${discount}</em>`;
    },
    setPaymentMethod(paymentMethod) {
      this.changePaymentMethod({ paymentMethod });
    },
    checkProfile() {
      let check;

      if (this.isArtentina) {
        check =
          !this.getProfile?.person?.cpf_cnpj ||
          !this.getProfile?.person?.owner ||
          !this.getProfile?.billingAddress?.street ||
          !this.getProfile?.billingAddress?.city ||
          !this.getProfile?.billingAddress?.state;
      } else {
        check =
          !this.getProfile?.person?.owner ||
          !this.getProfile?.billingAddress?.street ||
          !this.getProfile?.billingAddress?.city;
      }

      if (!check) {
        return this.$router.push({
          name: CHECKOUT_PROFILE_EDIT_VIEW,
        });
      }
    },

    getButtomText(item) {
      if (item === "creditCard") {
        return this.$t("checkout.pay_credit_card");
      }

      return this.$t("checkout.pay_bankslip", { paymentMethod: item });
    },

    getPaymentFunction(item) {
      if (item === "creditCard") {
        this.nextStepCreditCard("CreditCard");
        this.eventGtm("custom.paymentCreditCard");
      }

      return this.nextStepBankslip(Utils.capitalizeFirstLetter(item));
    },
  },
};
</script>

<style lang="scss">
.submit-label {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.payment-btns {
  /* width: 80%; */
  width: 300px;
  padding: 10px;
}
.advance-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
}
</style>

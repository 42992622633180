export const GET_PLAN = 'GET_PLAN'
export const GET_CHOSEN_PLAN = 'GET_CHOSEN_PLAN'
export const SET_CHOSEN_PLAN = 'SET_CHOSEN_PLAN'
export const GET_PLANS = 'GET_PLANS'
export const GET_PLAN_SELECTED_RECURRENCIES = 'GET_PLAN_SELECTED_RECURRENCIES'
export const SET_PLAN_SELECTED_RECURRENCIES = 'SET_PLAN_SELECTED_RECURRENCIES'
export const SET_PLANS = 'SET_PLANS'
export const SET_PLAN = 'SET_PLAN'
export const GET_ORDER = 'GET_ORDER'
export const SET_ORDER = 'SET_ORDER'
export const GET_RECURRENCE = 'GET_RECURRENCE'
export const SET_RECURRENCE = 'SET_RECURRENCE'
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const GET_PAYMENT_OPTIONS = 'GET_PAYMENT_OPTIONS'
export const SET_PAYMENT_OPTIONS = 'SET_PAYMENT_OPTIONS'
export const GET_PLANS_MONTHLY = 'GET_PLANS_MONTHLY'
export const SET_PLANS_MONTHLY = 'SET_PLANS_MONTHLY'
export const GET_PLANS_NAME = 'GET_PLANS_NAME'
export const SET_PLANS_NAME = 'SET_PLANS_NAME'
export const GET_CREDIT_CARD_TOKEN_ERRORS = 'GET_CREDIT_CARD_TOKEN_ERRORS'
export const SET_CREDIT_CARD_TOKEN_ERRORS = 'SET_CREDIT_CARD_TOKEN_ERRORS'
export const SET_CREDIT_CARD_TOKEN = 'SET_CREDIT_CARD_TOKEN'
export const CHANGE_CHOSEN_PLAN = 'CHANGE_CHOSEN_PLAN'
export const CHANGE_PLAN = 'CHANGE_PLAN'
export const CHANGE_RECURRENCE = 'CHANGE_RECURRENCE'
export const CHANGE_ORDER = 'CHANGE_ORDER'
export const CHANGE_PAYMENT_METHOD_BANKSLIP = 'CHANGE_PAYMENT_METHOD_BANKSLIP'
export const CHANGE_PAYMENT_METHOD_CREDIT_CARD = 'CHANGE_PAYMENT_METHOD_CREDIT_CARD'
export const CHANGE_PLAN_SELECTED_RECURRENCIES = 'CHANGE_PLAN_SELECTED_RECURRENCIES'
export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'
export const EXEC_CHECKOUT = 'EXEC_CHECKOUT'
export const CREATE_CREDIT_CARD_TOKEN = 'CREATE_CREDIT_CARD_TOKEN'
export const CHECKOUT_MODULE = 'checkout'
export const SET_PENDING = 'SET_PENDING'
export const CHANGE_PENDING = 'CHANGE_PENDING'
export const GET_PENDING = 'GET_PENDING'
export const GET_COUNTRY = 'GET_COUNTRY'

import { SET_PAYMENT_METHOD, SET_PLANS_MONTHLY, SET_PLANS_NAME, SET_PLAN, SET_PLANS, SET_RECURRENCE, SET_PLAN_SELECTED_RECURRENCIES, SET_CHOSEN_PLAN, SET_ORDER, SET_CREDIT_CARD_TOKEN_ERRORS, SET_CREDIT_CARD_TOKEN, SET_PENDING, SET_PAYMENT_OPTIONS } from '@/store/constants/checkout';

export default {
  [SET_PLAN]: (state, plan) => state.plan = plan,
  [SET_PLANS]: (state, plans) => state.plans = plans,
  [SET_PLANS_NAME]: (state, plansName) => state.plansName = plansName,
  [SET_RECURRENCE]: (state, recurrence) => state.recurrence = recurrence,
  [SET_PAYMENT_METHOD]: (state, paymentMethod) => state.paymentMethod = paymentMethod,
  [SET_PAYMENT_OPTIONS]: (state, paymentOptions) => state.paymentOptions = paymentOptions,
  [SET_PLANS_MONTHLY]: (state, plansMonthly) => state.plansMonthly = plansMonthly,
  [SET_PLAN_SELECTED_RECURRENCIES]: (state, planRecurrencies) => state.planSelectedRecurrencies = planRecurrencies,
  [SET_CHOSEN_PLAN]: (state, chosenPlan) => state.chosenPlan = chosenPlan,
  [SET_ORDER]: (state, order) => state.order = order,
  [SET_CREDIT_CARD_TOKEN_ERRORS]: (state, value) => state.creditCardTokenErrors = value,
  [SET_CREDIT_CARD_TOKEN]: (state, value) => state.creditCardToken = value,
  [SET_PENDING]: (state, pending) => {
    state.pending = pending
    localStorage.setItem('pending', pending)
    }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: { "back-button-action": _vm.backBtnAction },
        scopedSlots: _vm._u([
          {
            key: "back",
            fn: function () {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$t("checkout.back_checkout_edit")) + " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "header",
            fn: function () {
              return [
                [
                  _c(
                    "span",
                    { attrs: { "data-cy": "choose-wizard_control-text" } },
                    [_vm._v(" 2 " + _vm._s(_vm.$t("checkout.of")) + " 2 — ")]
                  ),
                ],
                _c(
                  "strong",
                  { attrs: { "data-cy": "choose-wizard_control_choose-text" } },
                  [_vm._v(" " + _vm._s(_vm.$t("checkout.nf_emit")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "sub-header",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("plan")) + " "),
                _c(
                  "strong",
                  { attrs: { "data-cy": "payment_method-plan-text" } },
                  [
                    _vm._v(
                      "Premium " +
                        _vm._s(
                          _vm.$t("recurrences_num")[
                            _vm.getPlan?.recurrencePeriod
                          ]
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(" — "),
                _c("span", {
                  attrs: { "data-cy": "choose-plan_price-text" },
                  domProps: { innerHTML: _vm._s(_vm.planPrice) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "form",
        {
          staticClass: "form mt-8",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [
          _c("FormulateForm", { staticClass: "text-left" }, [
            _c("div", [
              _c(
                "div",
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: "text",
                      name: "company_name",
                      label: _vm.$t("form.company_owner"),
                      validation: "required",
                      "validation-messages": {
                        required: _vm.$t("form.required_field"),
                      },
                      "error-class": ["text-red-600"],
                      icon: "",
                      "outer-class": ["w-full"],
                      "data-cy": "profile_form-company_name-input",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.eventGtm("custom.editCompanyName")
                      },
                    },
                    model: {
                      value: _vm.getProfile.person.owner,
                      callback: function ($$v) {
                        _vm.$set(_vm.getProfile.person, "owner", $$v)
                      },
                      expression: "getProfile.person.owner",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-wrap" },
                [
                  _vm.isArgentina
                    ? _c("FormulateInput", {
                        attrs: {
                          type: "select",
                          name: "documentType",
                          label: _vm.$t("form.document_type"),
                          options: _vm.tipoDocList,
                          "outer-class": ["w-full sm:w-1/3 sm:pl-1 md:w-1/3"],
                          "error-class": ["text-red-600"],
                          "data-cy": "profile_form-document-type",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.eventGtm("custom.editDocumentType")
                          },
                        },
                        model: {
                          value: _vm.profile.person.doc_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.profile.person, "doc_type", $$v)
                          },
                          expression: "profile.person.doc_type",
                        },
                      })
                    : _vm._e(),
                  _c("FormulateInput", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.mask,
                        expression: "mask",
                      },
                    ],
                    attrs: {
                      type: "text",
                      label: _vm.$t("form.document_number"),
                      name: "document",
                      icon: "id-card",
                      inputmode: "text",
                      validation: "required",
                      "validation-rules": {
                        valid: () => _vm.validateDocument,
                      },
                      "validation-messages": {
                        required: _vm.$t("form.required_field"),
                        valid: _vm.$t("form.invalid"),
                      },
                      "error-class": ["text-red-600"],
                      "outer-class": [this.defaultClass],
                      "data-cy": "profile_form-document-number",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.eventGtm("custom.editDocument")
                      },
                    },
                    model: {
                      value: _vm.profile.person.cpf_cnpj,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile.person, "cpf_cnpj", $$v)
                      },
                      expression: "profile.person.cpf_cnpj",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("form-address", {
            attrs: { "address-type": "billingAddress" },
            on: {
              addressData: _vm.updateBillingAddress,
              setError: _vm.setError,
            },
          }),
          _c(
            "div",
            { staticClass: "mt-6" },
            [
              _c("submit-button-default", {
                attrs: {
                  text: _vm.$t("advance"),
                  "data-cy": "checkout_edit-submit-btn",
                  loading: _vm.loading,
                  disabled: !_vm.stepValid_2 || !_vm.stepValid_1 || this.error,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.eventGtm("custom.nextUpdateBillingAddress")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }